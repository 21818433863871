.flex-container {
    display: flex;
    justify-content: space-between;
    /* Aligns text on the left and buttons on the right */
    align-items: center;
    /* Vertically centers both the text and the buttons */
}

.text-left {
    font-size: 14px;
    font-family: system-ui;
}

.button-align {
    display: flex;
    gap: 10px;
    /* Adds space between the buttons */
}