.sticky {
  position: sticky;
  top: 0;
}

.code_text {
  color: rgb(4, 5, 27);
  font-weight: 500;
  font-size: 15px;
  font-family: Consolas;
}

.image-container {
  width: auto !important;
  height: 300px;
  overflow: hidden;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  border: 2px solid #5f2985;
  border-radius: 7px;
}

.image-container img {
  width: 100%;
  height: 100%;
}

.paymentImage {
  max-width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto !important;
}

h5 {
  color: yellow;
}