.read-more-button {
    text-decoration: none;
    font-size: 14px;
    font-weight: 700;
    font-family: Papyrus, fantasy;


}

.head-Title {
    font-family: Helvetica, sans-serif;
    font-weight: 700;
    min-height: 50px;
}

.title-card {
    max-height: 28rem;
    min-height: 25rem;

}